import React from "react";
import {
  ArrowRightIcon,
  LocationMarkerIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import { splitDate } from "lib/formatDate";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";

export default function NodeEventTeaser({ node, theme = "default" }) {
  const router = useRouter();
  const { lang } = useTranslation("common");
  const date = splitDate(node.field_date_start, lang);
  const dateEnd = node.field_date_end
    ? splitDate(node.field_date_end, lang)
    : null;

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  return (
    <article
      className="group flex flex-col cursor-pointer h-full"
      onClick={() => clickHandler()}
    >
      <div
        className={classNames(
          "relative p-10 bg-white",
          theme !== "borderless" && "border border-secondary border-opacity-50"
        )}
      >
        {date && (
          <div className="flex items-baseline uppercase mb-6">
            <span className="inline-block text-6xl font-serif">{date.day}</span>{" "}
            <span className="text-primary text-sm">&#47;</span>{" "}
            <span className="text-sm">{date.month}</span>
            {dateEnd && JSON.stringify(date) !== JSON.stringify(dateEnd) && (
              <>
                <ArrowRightIcon className="h-4 w-4 mx-2" />
                <span className="inline-block text-6xl font-serif">
                  {dateEnd.day}
                </span>{" "}
                <span className="text-primary text-sm">&#47;</span>{" "}
                <span className="text-sm">{dateEnd.month}</span>
              </>
            )}
          </div>
        )}

        <h2 className="text-2xl mb-6 font-serif">
          <Link href={node.path.alias} prefetch={false} passHref>
            <a className="underline underline-offset-4 decoration-transparent transition-colors group-hover:decoration-primary">
              {node.title}
            </a>
          </Link>
        </h2>

        {node.field_type && (
          <div className="flex items-center text-secondary">
            <TicketIcon className="inline-block text-primary h-6 w-6 mr-4" />
            {node.field_type.name}
          </div>
        )}

        {node.field_location && (
          <div className="flex items-center mt-4 text-secondary">
            <LocationMarkerIcon className="inline-block text-primary h-6 w-6 mr-4" />
            {node.field_location}
          </div>
        )}
      </div>
    </article>
  );
}
