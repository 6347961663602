import React from "react";
import { Controller, Navigation } from "swiper";
// eslint-disable-next-line
import { Swiper, SwiperSlide } from "swiper/react";
import NodeEventTeaser from "components/Event/NodeEventTeaser";

export default function NodeEventList({ nodes, theme = "default" }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
      {nodes.map((node) => (
        <div key={node.id}>
          <NodeEventTeaser node={node} theme={theme} />
        </div>
      ))}
    </div>
  );
}

export function NodeEventListPreview({ nodes, nextRef, prevRef, setSwiper }) {
  return (
    <Swiper
      modules={[Navigation, Controller]}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      }}
      navigation={{
        prevEl: prevRef?.current,
        nextEl: nextRef?.current,
      }}
      spaceBetween={50}
      onSwiper={setSwiper}
    >
      {nodes.map((node) => (
        <SwiperSlide key={node.id}>
          <NodeEventTeaser node={node} theme="borderless" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
