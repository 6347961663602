export function formatDate(input = null, lang = "fr") {
  const date = new Date(input);
  const locales = {
    fr: "fr-FR",
    en: "en-GB",
    de: "de-DE",
    nl: "nl-NL",
  };

  return date.toLocaleDateString(locales[lang], {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function formatDateYMD(input = null) {
  const date = new Date(input);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
}

export function splitDate(input = null, lang = "fr") {
  const date = new Date(input);
  const locales = {
    fr: "fr-FR",
    en: "en-GB",
    de: "de-DE",
    nl: "nl-NL",
  };

  return {
    month: date.toLocaleDateString(locales[lang], {
      month: "long",
    }),
    day: date.toLocaleDateString(locales[lang], {
      day: "numeric",
    }),
    year: date.toLocaleDateString(locales[lang], {
      year: "numeric",
    }),
  };
}
