import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";

export default function SlidePrevButton({ prevRef, theme }) {
  switch (theme) {
    case "rounded-outline":
      return (
        <div
          className="swiper-button absolute -left-2 md:left-2 top-1/2 -translate-y-1/2"
          ref={prevRef}
        >
          <div className="hidden sm:block p-3 rounded-full border border-secondary/20 cursor-pointer hover:border-primary transition-all">
            <ArrowLeftIcon className="w-5 h-5" />
          </div>
        </div>
      );
    case "full-square":
      return (
        <div
          className="swiper-button p-6 bg-primary-light hover:bg-primary transition-colors cursor-pointer"
          ref={prevRef}
        >
          <div className="hidden sm:block cursor-pointer transition-all">
            <ArrowLeftIcon className="w-5 h-5" />
          </div>
        </div>
      );
    default:
      return (
        <div className="swiper-button absolute -left-2 md:left-2 top-1/2 -translate-y-1/2">
          <div
            className="hidden sm:block p-3 rounded-full border border-secondary/20 cursor-pointer hover:border-primary transition-all"
            ref={prevRef}
          >
            <ArrowLeftIcon className="w-5 h-5" />
          </div>
        </div>
      );
  }
}
