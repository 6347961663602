import React from "react";
import Link from "next/link";
import Button from "components/Button";
import useTranslation from "next-translate/useTranslation";
import { NodeEventListPreview } from "components/Event/NodeEventList";
import SlidePrevButton from "components/Swiper/SlidePrevButton";
import SlideNextButton from "components/Swiper/SlideNextButton";

export default function HomepageEvent({ nodes, prevRef, nextRef, setSwiper }) {
  const { t } = useTranslation("home");

  return (
    <section
      id="events"
      className="relative py-20 bg-secondary-light overflow-hidden"
    >
      <div
        className="absolute top-0 inset-x-0 h-1/2 bg-secondary-dark"
        aria-hidden="true"
      />
      <div className="container relative mx-auto px-4">
        <div
          className="absolute top-0 left-1/2 text-left text-white text-[210px] font-serif leading-none opacity-5"
          aria-hidden="true"
        >
          {t("events.decoration")}
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center gap-4 mb-20 text-white">
          <h2 className="flex-1 text-5xl font-serif">{t("events.title")}</h2>

          <p className="flex-initial text-white">
            <span
              className="inline-block h-2 w-2 mr-4 border-2 border-primary rounded-full"
              aria-hidden="true"
            />
            {t("events.baseline")}
          </p>
        </div>

        <NodeEventListPreview nodes={nodes.events} setSwiper={setSwiper} />

        <div className="relative flex justify-center md:justify-start mt-16">
          <div className="flex-initial">
            <Link href={t("common:event.path")} prefetch={false} passHref>
              <Button as="a">{t("events.more")}</Button>
            </Link>
          </div>

          <div className="flex-1 hidden md:flex justify-end">
            <SlidePrevButton theme="full-square" prevRef={prevRef} />
            <SlideNextButton theme="full-square" nextRef={nextRef} />
          </div>
        </div>
      </div>
    </section>
  );
}
